import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { OurClients } from '../components/Clients/OurClients';
import { Contact } from '../components/ContactButton/Contact';
import { NewsItems } from '../components/NewsItem/NewsItems';
import { EBook } from '../components/Others/E-Book/E-Book';
import { Winepad } from '../components/Others/Winepad/Winepad';
import { Root } from '../components/Root';
import { ServiceBlock } from '../components/ServiceBlock/ServiceBlock';
import { VerticalLine } from '../components/UI/Branding/VerticalLine';
import { Button } from '../components/UI/Button';
import { Column } from '../components/UI/Column';
import { Desktop } from '../components/UI/Desktop';
import { Heading } from '../components/UI/Heading';
import { Mobile } from '../components/UI/Mobile';
import { NoSelect } from '../components/UI/NoSelect';
import { Row } from '../components/UI/Row';
import { UnstyledLink } from '../components/UI/UnstyledLink';
import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';

import CBlackUrl from './../graphics/CBlack.svg';
import WBlackUrl from './../graphics/WBlack.svg';
import XBlackUrl from './../graphics/XBlack.svg';
import XBlackLong from './../graphics/XBlackLong.svg';
import XBlackMobileUrl from './../graphics/XBlackMobile.svg';

export const query = graphql`
  query team {
    team: sanityTeam(Role: { eq: "Team" }) {
      Photo {
        asset {
          fluid(maxWidth: 1200) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        Alt
      }
    }
    sanityContactInfo {
      phone
    }
    allSanityClients {
      nodes {
        Photo {
          asset {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        },
        Name,
        Url
      }
    }
  }
`;

export default ({ data }) => {
  const teamPhoto = data.team.Photo;
  const phoneNumber = data.sanityContactInfo.phone;
  const clients = data.allSanityClients.nodes;

  return (
    <Root>
      <C src={CBlackUrl} alt="black-c" />
      <W src={WBlackUrl} alt="black-w" />
      <X src={XBlackUrl} alt="black-x" />
      <XMobile src={XBlackMobileUrl} alt="black-x-mobile" />

      <Intro>
        <Mobile>
          <Image
            fluid={teamPhoto.asset.fluid}
            alt={teamPhoto.Alt}
          />
          {/* <ActionButton mode="green">Ontdek hoe</ActionButton> */}
        </Mobile>
        <Desktop>
          <Row>
            <Column direction={'column'} breakpoints={{ [Breakpoint.TabletPortrait]: 5 }}>
              <Heading type="h1">Crossworx helpt mensen met digitale ambities.</Heading>
              {/* <ActionButton mode="green" asDiv>Klinkt goed</ActionButton> */}
            </Column>
            <Column direction={'column'} breakpoints={{ [Breakpoint.TabletPortrait]: 1 }} />
            <Column direction={'column'} breakpoints={{ [Breakpoint.TabletPortrait]: 6 }}>
              <Image
                fluid={teamPhoto.asset.fluid}
                alt={'Team photo'}
              />
            </Column>
          </Row>
        </Desktop>
      </Intro>

      <VerticalLine />

      <NewsContainer>
        <StyledHeading type="h2">Nieuwigheden</StyledHeading>
        <NewsItems />
      </NewsContainer>

      <WeBringItContainer>
        <BringItText type="h2">Wij brengen alles samen.</BringItText>
        <ServiceBlocks>
          <ServiceX src={XBlackLong} alt="black-x" />
          <ServiceBlock text={'Apps'} link={'/diensten/#apps'} />
          <ServiceBlock text={'Website'} link={'/diensten/#apps'} />
          <ServiceBlock text={'Animaties'} link={'/diensten/#animatie'} />
          <ServiceBlock text={'Foto en video'} link={'/diensten/#photoVideo'} />
          <ServiceBlock text={'Copywriting'} link={'/diensten/#copywriting'} />
          <ServiceBlock text={'Branding'} link={'/diensten/#apps'} />
        </ServiceBlocks>
        <ButtonContainer>
          <UnstyledLink to="/diensten">
            <StyledButton mode="grey">
              Ontdek al onze diensten
            </StyledButton>
          </UnstyledLink>
        </ButtonContainer>
      </WeBringItContainer>
      {/* <EBookContainer>
        <EBook
          title="Klaar voor je website?"
          subtitle="Met ons e-book zet je alles op een rijtje."
          description="Eerst en vooral een open deur: jouw project is uniek.  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna urna, 
                      ulputate a tempor eu, tincidunt non libero. Nulla sodales augue a sodales congue. Mauris at eros sodales, pellentesque sapien ut, hendrerit nisi lentesqu."
          pdfLink="" />
      </EBookContainer> */}
      <Winepad />
      <OurClientsContainer>
        <OurClients clients={clients} />
      </OurClientsContainer>
      <ReadyContainer>
        <ReadyHeading type="h2">Wij zijn er klaar voor.</ReadyHeading>
        <Contact
          displayText={true}
          buttonTitle="Mailtje sturen"
          buttonColor="green"
          phoneNumber={phoneNumber}
          phoneFontColor="white"
        />
      </ReadyContainer>
    </Root >
  );
};

const OurClientsContainer = styled.div`
  margin-bottom: 15rem;
`;

const Intro = styled.div`
  margin-bottom: 5rem;
`;

const ActionButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 2.5rem;
`;

function Letter() {
  return css`
    ${NoSelect()}
    position: absolute;
    overflow: hidden;
  `;
}

const C = styled.img`
  ${Letter()}

  top: 20rem;
  left: -5rem;
  ${forBreakpoint(Breakpoint.Phone, css`
    max-width: 15rem;
  `)};

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    left: -3rem;
  `)};
  ${forBreakpoint(Breakpoint.Desktop, css`
    max-width: 17rem;
  `)};
`;

const W = styled.img`
  ${Letter()}

  top: 15rem;
  right: -4.5rem;

  ${forBreakpoint(Breakpoint.Phone, css`
    max-width: 15rem;
  `)};
  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    top: 19rem;
  `)};
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    top: 22rem;
  `)};
  ${forBreakpoint(Breakpoint.Desktop, css`
    max-width: 25rem;
    top: 17rem;
  `)};
`;

const X = styled.img`
  ${Letter()}

  top: -1rem;
  right: 2.5rem;
  display: none;

  ${forBreakpoint(Breakpoint.Phone, css`
    max-width: 25rem;
  `)};
  
  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    right: 30%;
    max-width: 23rem;
  `)};

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    right: 40%;
    max-width: 26.4rem;
    display: block;
  `)};
  
  ${forBreakpoint(Breakpoint.Desktop, css`
    right: 32%;
    max-width: 29.6rem;
  `)};
`;

const XMobile = styled.img`
  ${Letter()}

  top: -1rem;
  right: 2.5rem;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: none;
  `)};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0 0 0;
`;

const StyledButton = styled(Button)`
  color: white;
  margin: 0 auto;
  z-index: 1;
`;

const WeBringItContainer = styled.div`
  margin-top: 5rem;
  margin-bottom: 3rem;

${forBreakpoint(Breakpoint.TabletLandscape, css`
  margin-bottom: 8rem;
`)};
`;

const BringItText = styled(Heading)`
  text-align: center;
  margin-bottom: 3rem;
`;

const ServiceBlocks = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5rem;
  position: relative;
  z-index: 1;
`;

const EBookContainer = styled.div`
  display: none;
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: block;
    margin-bottom: 8rem;
  `)};
`;

const ServiceX = styled.img`
  display: none;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
  display: block;
  position: absolute;
  max-width: 900px;
  top: -25vh;
  right: -15vw;
  z-index: -1;
`)};

  ${forBreakpoint(Breakpoint.Desktop, css`
  top: -30vh;
  right: -21vw;
  z-index: -1;
`)};
`;

const NewsContainer = styled.div`
  display: none;
  margin-top: 5rem;

  ${forBreakpoint(Breakpoint.Desktop, css`
    display: block;
    margin-bottom: 7rem;
  `)};
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 3rem;
`;

const Image = styled(Img)`
  ${NoSelect()}

  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 2rem;

  border-radius: 20px;
`;

const ReadyContainer = styled.div`
  margin: 4rem auto 6rem auto;

  ${forBreakpoint(Breakpoint.Phone, css`
    max-width: 200px;
  `)};

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    max-width: 500px;
    margin: 0 auto 6rem  auto;
`)};
`;

const ReadyHeading = styled(Heading)`
  color: #00F2B2;
  font-size: 2.5rem;
  text-align: center;
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    font-size: 3rem;
`)};
`;